const Login = () => import(/* webpackChunkName: "login" */ './../../components/Home')
const Signup = () => import(/* webpackChunkName: "register" */ './../../components/auth/Signup')
const Registration = () => import(/* webpackChunkName: "register" */ './../../components/auth/Registration')
const ForgotPassword = () => import(/* webpackChunkName: "forgot_password" */ './../../components/auth/ForgotPassword')
const ResetPassword = () => import(/* webpackChunkName: "forgot_password" */ './../../components/auth/ResetPassword')
const SetPassword = () => import(/* webpackChunkName: "forgot_password" */ './../../components/auth/SetPassword')

export const auth = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Registration',
        component: Registration
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/reset-password/:email/:encrypt',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/set-password/:email/:encrypt',
        name: 'SetPassword',
        component: SetPassword
    },
    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup
    }
]