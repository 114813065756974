import axios from '../../axios'

const state = {
    loans: [],
    productCode: null,
    schedule_loans: [],
    payment: null,
    loan_application: []
}

const getters = {
    loans: state =>state.loans,
    productCode: state =>state.productCode,
    payment: state =>state.payment,
    schedule_loans: state =>state.schedule_loans,
    loan_application: state =>state.loan_application,
}

const mutations = {
    loans(state, payload) {
        state.loans = [...state.loans, payload]
    },
    loan_application(state, payload) {
        state.loan_application = [...state.loan_application, payload]
    },
    payment(state, payload) {
        state.payment = payload
    },
    productCode(state, payload) {
        state.productCode = payload
    },
    schedule_loans(state, payload) {
        state.schedule_loans = [...state.schedule_loans, payload]
    },
}

const actions = {
    async GetAllLoans({commit, state}, payload){
        const response = await axios.get(`/api/v1/loan/${payload}`)
        if (response.status) {
            if (response.data.length) {
                state.loans = []
                state.schedule_loans = []
                response.data.map(value => {
                    commit('loans', value)
                    if (value.loan_schedule.length){
                        value.loan_schedule.map(val => {
                            const {amount,appl_type,applicant_ids,approved_by,branch,capital,cl_acc_no,company_id,customer_acc_id,financing_amt,
                                id, install_count, is_company_customer,loanbal,loaninterest,mature_date,monthly_debt1,monthly_debt2,
                                monthlypayment,name,no_of_employees,partner_id,prelim_app_id,prelim_id,requested_amt,schedule_ids,status,sub_branch} = value
                            let loan_schedule = val
                            commit('schedule_loans', {amount,appl_type,applicant_ids,approved_by,branch,capital,cl_acc_no,company_id,customer_acc_id,financing_amt,
                                id, install_count, is_company_customer,loanbal,loaninterest,mature_date,monthly_debt1,monthly_debt2,
                                monthlypayment,name,no_of_employees,partner_id,prelim_app_id,prelim_id,requested_amt,schedule_ids,status,sub_branch,loan_schedule})
                        })
                    }
                })
            }
        }
        return response
    },
    async PaymentCompleted ({commit}, payload){
        try {
            const response = await axios.post('/api/v1/online/payment/', payload)
            if (response.status){
                commit('setup/message',
                    'Payment completed, wait for plural capital to approve you loan',
                    {root: true})
                return response
            }
        } catch (e) {
            commit('setup/errors', 'Payment was not complete', { root: true })
        }
    },
    async ApplicationId ({commit}, payload) {
        try {
            const response = await axios.post('/api/v1/loan/prelim/application/create', payload)
            if (response.status) {
                commit('setup/message', response.data, {root: true})
                return response
            }
        } catch (e) {
            commit('setup/errors', 'Loan application was not successful', {root: true})
        }
    },

    async Branch () {
        const response = await axios.get('/api/v1/loan/sub/branch')
        if (response.status) {
            return response.data
        }
    },

    async BranchOfficer () {
        const response = await axios.get('/api/v1/loan/officer')
        if (response.status) {
            return response.data
        }
    },

    async LoanApplication ({commit, state}, payload) {
        try {
            const response = await axios.get(`/api/v1/loan/prelim/application/${payload}`)
            if (response.status) {
                state.loan_application = []
                response.data.map(value => {
                    commit('loan_application', value)
                })
                return response
            }
        } catch (e) {
            commit('setup/errors', 'No loan application', { root: true })
        }
    },

    async CountriesOdoo () {
        const response = await axios.get('/api/v1/loan/country/insurance')
        if (response.status) {
            return response.data
        }
    },

    async ProductCode ({commit}) {
        const response = await axios.get('/api/v1/loan/product_code')
        if (response.status) {
            commit('productCode', response.data)
            return response
        }

    }
}

export default {
    name: 'loan',
    namespaced: true,
    state, getters, mutations, actions
}

