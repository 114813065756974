const state = {
    errors: '',
    message: '',
    apply: 0,
    pdf: [],
    application: null,
    loading: false,
    isToggle: false,
}
const mutations = {
    loading (state, payload) {
        state.loading = payload
    },
    apply (state, payload) {
        state.apply = payload
    },
    pdf (state, payload) {
        state.pdf = [...state.pdf, payload]
    },
    application (state, payload) {
        state.application = payload
    },
    errors (state, payload) {
        state.errors = payload
    },
    message (state, payload) {
        state.message = payload
    },
    isToggle (state, payload) {
        state.isToggle = payload
    },
}
const actions = {
    pdfJoins({state}) {
        state.pdf = []
    }
}
const getters = {
    pdf: state => state.pdf,
    errors: state => state.errors,
    apply: state => state.apply,
    message: state => state.message,
    loading: state => state.loading,
    isToggle: state => state.isToggle,
}


export default {
    namespaced: true,
    state, getters, mutations, actions
}
