import axios from "./../../axios";
import Helper from "../../config/Helper";

let isUser = sessionStorage.getItem('ypotrofía')

const state = {
    user: isUser ?
        Helper.isUserDetails(isUser) : '',
    isLoggedIn: !!isUser,
    partner: null,
}

const getters = {
    user: state => state.user,
    partner: state => state.partner,
    isLoggedIn: state => !!state.isLoggedIn,
}

const mutations = {
    user (state, payload) {
        state.user = payload
    },
    token(state, payload) {
        state.isLoggedIn = payload
    },
    partner(state, payload) {
        state.partner = payload
    }
}

const actions = {

    async AuthLogin ({commit}, payload) {
        try {
            const response = await axios.post('/api/v1/users/user', payload)
            if (response.status === 200) {
                const { name, partner_id, create_date, display_name, image,image_medium,
                    image_small,login, bankbvnno, banknubanno, contact_address, mobile,
                    nokphoneon, phone, banktype
                } = response.data[0]
                const personal = { name, partner_id,bankbvnno, banknubanno, contact_address, mobile,
                    nokphoneon, phone, banktype, create_date, display_name, image,image_medium,image_small,login }
                Helper.isCheckUser(personal)
                commit('token', true)
                commit('user', personal)
                commit('setup/message', 'Logged in successful', { root: true })
                return response
            } else {
                let err = "Invalid credential"
                commit('setup/errors', err, { root: true })
                return response
            }
        } catch (e) {
            let err = "Invalid credential, login not allowed"
            commit('setup/errors', err, { root: true })
        }
    },
    async Logout ({commit}) {
        sessionStorage.removeItem('ypotrofía')
        let err = "Logged out of plural capital platform"
        commit('user', '')
        commit('token', '')
        commit('setup/message', err, { root: true })
    },
    async PartnerSelections ({commit}, payload) {
        const response = await axios.get(`/api/v1/loan/partner/${payload}`)
        if (response.status) {
            commit('partner', response.data[0])
        }
        return response
    },

    async ForgotPasswordSent ({commit}, payload) {
        try {
            const response = await axios.post(`/api/v1/users/set/password`, payload)
            if (response.status) {
                commit('setup/message', 'Password change was successful', {root: true})
                return response
            }
            return response
        } catch (e) {
            commit('setup/errors', 'Password was not change', {root: true})
        }
    },

    async ForgotPasswordEmail ({commit}, payload) {
        try {
            const formDate = new FormData
            formDate.append('email', payload.email)
            const response = await axios.post(`/api/v1/users/reset/password`, formDate)
            if (response.status) {
                commit('setup/message', `Please go ${payload.email} 
                follow the instruction to set your password`, {root: true})
                return response
            }
            return response
        } catch (e) {
            commit('setup/errors', 'Password recovery not sent', {root: true})
        }
    },

    async PartnerUserUpdate ({commit, state}, payload) {
        let formDate = new FormData ()
        formDate.append('maiden_name', 'dhdhj djdjjd jdjjd')
        const response = await axios.get(`/api/v1/loan/partner/update/${state.user.partner_id[0]}`, payload)
        if (response.status) {
            const res = await axios.get(`/api/v1/loan/partner/${state.user.partner_id[0]}`)
            commit('setup/message', 'update', {root: true})
            if (res.status) {
                // gdgdg
            }
            return response
        }
    }
}

export default {
    name: 'auth',
    namespaced: true,
    state, getters, mutations, actions
}
