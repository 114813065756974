
import CryptoJS from 'crypto-js'

export default class Helper {
    static isCheckUser (value) {
        let user
        if (Helper.isObject(value)) {
            user = Helper.isRestValue(JSON.stringify(value))
        } else {
            user = Helper.isRestValue(value)
        }

        return sessionStorage.setItem('ypotrofía', user)
    }

    static isUserDetails (value) {
        let user = Helper.userCheck(value).toString(CryptoJS.enc.Utf8)
        return JSON.parse(user)
    }

    static userCheck (value) {
        return CryptoJS.AES.decrypt(value, process.env.VUE_APP_ENCRYPTION_SECRET_KEY)
    }

    static isObject(value) {
        return value instanceof Object && !Array.isArray(value)
    }

    static isRestValue (value) {
        return CryptoJS.AES.encrypt(value,
            process.env.VUE_APP_ENCRYPTION_SECRET_KEY).toString()
    }
}
