<template>
  <div id="app" >
    <router-view />
    <div id="load" v-if="loading">
      <div class="loader">Loading...</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'App',
  components: {},
  methods: {
    ...mapActions({
      LogoutUser: 'auth/Logout',
    }),
    async UserLogout () {
      try {
        const response = await this.LogoutUser()
        if (response.status) {
          window.location.replace(window.location.origin)
        }
      } catch (e) {
        window.location.replace(window.location.origin)
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'setup/loading'
    })
  },
  onIdle() {
    this.UserLogout()
  },
}
</script>

<style>
#app {
  position: relative;
}

</style>
