import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import setup from './setup'
import loan from './loan'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {setup, auth, loan}
})
