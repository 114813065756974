import auth from "../middleware/auth"

const Home = () => import(/* webpackChunkName: "auth" */ './../../components/Home')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './../../components/home/Dashboard')
const ScheduleDesigns = () => import(/* webpackChunkName: "designs" */ './../../components/home/pages/ScheduleDesigns')
const Employment = () => import(/* webpackChunkName: "employment" */ './../../components/home/Employment')
const Personal = () => import(/* webpackChunkName: "personal" */ './../../components/home/Personal')
const Loan = () => import(/* webpackChunkName: "loan" */ './../../components/home/Loan')
const Calculators = () => import(/* webpackChunkName: "loan" */ './../../components/home/Calculators')
const Account = () => import(/* webpackChunkName: "account" */ './../../components/home/Account')
const Disbursement = () => import(/* webpackChunkName: "disbursement" */ './../../components/home/Disbursement')
const Credentials = () => import(/* webpackChunkName: "credential" */ './../../components/home/Credentials')
const ScheduleReport = () => import(/* webpackChunkName: "credential" */ './../../components/home/ScheduleReport')
const Help = () => import(/* webpackChunkName: "help" */ './../../components/home/Help')
const Profile = () => import(/* webpackChunkName: "profile" */ './../../components/home/Profile')
const PayLoan = () => import(/* webpackChunkName: "payLoan" */ './../../components/home/PayLoan')
const LoanApplication = () => import(/* webpackChunkName: "Application Loans" */ './../../components/home/LoanApplication')
const LoanApplicationForms = () => import(/* webpackChunkName: "Application Forms" */ './../../components/home/LoanApplicationForms')

export const user = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/employment',
        name: 'Employment',
        component: Employment,
        meta: {
            title: 'Employment Role',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/test-schedule',
        name: 'ScheduleDesigns',
        component: ScheduleDesigns,
        meta: {
            title: 'Schedule Designs',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/calculators-report',
        name: 'Calculators',
        component: Calculators,
        meta: {
            title: 'Calculators',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/account-report',
        name: 'Account',
        component: Account,
        meta: {
            title: 'Account',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/personal',
        name: 'Personal',
        component: Personal,
        meta: {
            title: 'Personal',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/loan',
        name: 'Loan',
        component: Loan,
        meta: {
            title: 'Loan',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/schedule-report',
        name: 'ScheduleReport',
        component: ScheduleReport,
        meta: {
            title: 'ScheduleReport',
            middleware: [
                auth,
            ]
        },

    },
    {
        path: '/disbursement',
        name: 'Disbursement',
        component: Disbursement,
        meta: {
            title: 'Disbursement',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/loan-application',
        name: 'LoanApplication',
        component: LoanApplication,
        meta: {
            title: 'LoanApplication',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/loan-application-form',
        name: 'LoanApplicationForms',
        component: LoanApplicationForms,
        meta: {
            title: 'LoanApplicationForms',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            title: 'Profile',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/credentials',
        name: 'Credentials',
        component: Credentials,
        meta: {
            title: 'Credentials',
            middleware: [
                auth,
            ]
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            title: 'Help',
            middleware: [
                auth,
            ]
        },

    },
    {
        path: '/payload',
        name: 'PayLoan',
        component: PayLoan,
        meta: {
            title: 'Payloan',
            middleware: [
                auth,
            ]
        },
    },
]
