import axios from 'axios'
// import setup from './../config'

const token = localStorage.getItem('token')

// axios.defaults.baseURL = setup
axios.defaults.headers.post['Content-Type'] = 'application/json';


if (token) {
    axios.defaults.headers.common = {Authorization: `Token ${token}`};
}

// axios.interceptors.response.use(function (response) {
//     // auth.commit('auth/loading', false)
//     return response
// }, function (error) {
//     // auth.commit('auth/loading', false)
//     if (error.response.status === 401 || error.response.status === 403) {
//         localStorage.removeItem('token')
//         window.location.replace('/login')
//         return Promise.reject(error)
//     }
//     return Promise.reject(error)
// })

export default axios

