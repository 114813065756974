import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCurrencyInput from 'vue-currency-input'
import Validations from "@/mixins/Validations";
import VueToastr from 'vue-toastr'
import IdleVue from 'idle-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from "@/router";
import store from './store'

require('./assets/style/main.scss')
require('bootstrap')


Vue.config.productionTip = false

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'NGN', }
}

Vue.mixin({
  mixins: [Validations]
})
Vue.use(VueToastr, {
  defaultTimeout: 10000,
  defaultPosition: 'toast-top-full-width',
  defaultCloseOnHover: false,
  defaultClassNames: ["animated", "zoomInUp"]
})
window.$ = window.jQuery = require("jquery")

Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 4500000,
  store
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
