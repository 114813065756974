<script>
import {mapGetters} from 'vuex'
import * as rules from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

extend('email', {
    ...rules.email,

    message: field => {
        const rewriteWord = field.replace('_', ' ')
        return  `The ${rewriteWord} entered is not a valid email address`
    },
})
extend('required',
    {
        ...rules.required,

        message: field => {
            const rewriteWord = field.replace('_', ' ')
            return  `${rewriteWord} is required, please enter`
        },
    }
)

extend('conditions',
    {
        ...rules.required,

        message: 'Accept the terms and conditions',
    }
)

extend('checked',
    {
        ...rules.required,
        message: field => {
            const rewriteWord = field.replace('_', ' ')
            return  `${rewriteWord} must be checked before you proceed`
        },
    }
)

extend('min',{
    ...rules.min,
    message: (field, values) => {
        const rewriteWord = field.replace('_', ' ')
        return `The ${rewriteWord} must be greater than ${values.length} characters`
    }
})
extend('max',
    {
        ...rules.max,
        message: (field, values) => {
            const rewriteWord = field.replace('_', ' ')
            return `The ${rewriteWord} must be less than ${values.length} characters`
        }
    }
)

extend('size',
    {
        ...rules.size,
        message: 'You have exceeded the maximum file size',
    }
)

extend('between',
    {...rules.between}
)

extend('image',
    {...rules.image}
)

extend('dimension',
    {...rules.dimensions, message: `The maximum value must be less or equal 255`}
)

extend('numeric',
    {
        ...rules.numeric,
        message: field => `The ${field} must contain only numbers`,
    }
)

extend('url', {
  validate(value) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(value);
  },
  message: 'Please enter a valid url. e.g http://yourwebsite.com'
})

extend('alpha_num', {
    ...rules.alpha_num, message: "values must be contain alphabet number"
})

extend('ext', {
    ...rules.ext, message: "The file you select is not valid"
})

extend('confirmed', {
    ...rules.confirmed, message: "password did not match"
})


extend('uniqueEmail', {
    validate: async value => {
        const response = await this.$store.dispatch('auth/checkEmail', value);

        if (response.status) {
            return true;
        }

        return {
            valid: false,
            data: {
                // this will be used to interpolate the message.
                serverMessage: response.errors[0]
            }
        };
    },
    // same prop as returned in the `data` object.
    message: `{serverMessage}`
})

extend('password_confirmation', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
})

extend('checkboxes', {
    validate(value) {
        return value === true;
    },
    message: 'Accept the terms and condition'
})


export default {
  name: "ExtensionMixins",
  components: { ValidationProvider, ValidationObserver },
  methods: {
    getParagraph (post, value) {
        let body = this.stripTags(post);

        return body.length > value ? body.substring(0, value) + '...' : body;
    },

    stripTags (text) {
        return text.replace(/(<([^>]+)>)/ig, '');
    },

    formatters (value) {
      let cost, toss, sett, tt
      tt = parseInt(value)
      tt = 0.00 + tt
      cost = new Intl.NumberFormat('en-US',
          { style: 'currency', currency: 'NGN' }
      ).format(tt)
      sett = cost.replace('NGN', '₦')
      if (Math.sign(value) === -1) {
        toss = ` ${sett}`
      } else {
        toss = `${sett}`
      }
      return toss
    },
    remainingBalance(requiredAmount, investedAmount){
      let amount = null
      if (requiredAmount - investedAmount <= 0) {
        amount = '<p class="text-primary">Payment Completed</p>'
      } else if (requiredAmount > investedAmount) {
        const money = requiredAmount - investedAmount
        const forms = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        amount = forms.format(money + 0.00)
      }
      return amount
    },

    getLatestDateAvailable (values) {
      let sorted = values.map(value => {
        const lastDate = value.loan_schedule.schddate
        return {original_str: lastDate,
          in_ms: (new Date(lastDate)).getTime(), value }
      }).sort((value, item) => {
        return (value.in_ms < item.in_ms)
      })

      const latest = sorted[0]

      return latest
    },

    sortByUnpaidItems (value) {
      return value.sort(val => val.loan_schedule.paid_yn ? -1 : 1)
    },

    sortByRecentCreatedItems (value) {
      value.sort(function(a,b){
        return Number(new Date(b.create_date)) - Number(new Date(a.create_date));
      });

      return value;
    },

    sortByRecentMatureDates (value) {
      value.sort(function(a,b){
        return Number(new Date(b.mature_date)) - Number(new Date(a.mature_date));
      });

      return value;
    },

    sortOverDuePaymentAmount (value) {
      let ln = 0
      value.map(val => {
        if ((new Date() > new Date(val.loan_schedule.schddate)) && !val.loan_schedule.paid_yn) {
          ln = ln + val.loan_schedule.install_amount
        }
      })

      return ln;
    },
    sortOverDuePaymentCount (value) {
      let ln = []
      value.map(val => {
        if ((new Date() > new Date(val.loan_schedule.schddate)) && !val.loan_schedule.paid_yn) {
          ln.push(val)
        }
      })

      return ln.length;
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
  }
}

</script>
