import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import {auth} from "./auth";
import {user} from "./user";

import middlewarePipeline from './middleware/middlewarePipeline'


const routes = [
    ...auth, ...user
]

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
